.progress-bar {
    color: unset !important;
    background-color: important;

}

.table-mobile-only {
    display: none !important;
}

.table-desktop-only {
    display: table-cell !important;
}

.mobile-only {
    display: none !important;
}

.desktop-only {
    display: block !important;
}

.ip-add {
    fontWeight: bold;
    textTransform: uppercase;
    fontFamily: courier;
}

h1, h2, h3, h4, h5 {
    Xfont-family: "Parnaso Medium Condensed" !important;
}

.h1, .h2, .h3, .h4, .h5 {
    Xfont-family: "Parnaso Medium Condensed" !important;
}


/* when window narrower than 633 px, remove left padding from profile link */

/*

@media only screen and (max-width: 540px) {


    .profile-link {
        background-color: red;
        padding-left: 0px !important;
    }

}

*/

@media only screen and (max-width: 540px) {

    .table-mobile-only {
        display: table-cell !important;
    }

    .table-desktop-only {
        display: none !important;
    }

    .mobile-only {
        display: block !important;
    }

    .desktop-only {
        display: none !important;
    }

}